.App {
  text-align: center;
}

video {
  width: 100%;
  height: auto;
  outline: none;
}
.word:hover {
  color: rgb(3, 144, 187);
  /* cursor: pointer; */
}
.space {
  pointer-events: none;
}
.active {
  background-color: rgba(0, 100, 100, 0.2) !important;
  position: relative;
  z-index: 1;
}
.save-btn {
  float: right;
  border: thin solid #000;
  background: #000;
  color: #fff;
  cursor: pointer;
  padding: 3px 20px;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  outline: none !important;
}
.save-btn:hover {
  border: thin solid #000;
  background: #fff;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.search-btn {
  border: thin solid #000;
  background: #fff;
  color: #000;
  cursor: pointer;
  padding: 10px 20px;
  transition: all 0.5s ease-in-out;
  margin-left: 0 !important;
}
.search-btn:hover {
  background: #000;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
#editor {
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden !important;
  padding: 10px;
}
.content-row {
  margin: 20px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.time-stamp {
  display: inline-block;
  color: #fff;
  padding: 0 10px;
  border-radius: 20px;
  background-color: black !important;
}
.mark {
  background: rgba(255, 165, 0, 0.3);
  color: black;
  padding: 0;
  font-weight: 500;
}
.mark.active {
  background: rgba(255, 165, 0, 0.9);
  color: black;
  padding: 0;
  font-weight: 500;
}
.hilited {
  color: red;
}
.highlight-btn {
  background-color: #222;
  color: #fff;
  border: thin solid transparent;
  padding: 5px 10px;
  transition: all 0.5s ease-in-out;
  display: inline;
}
.highlight-btn:hover {
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  color: #17a2b8;
  border: thin solid #17a2b8;
}
.toolbar {
  background: #fff !important;
  border-bottom: thin solid #ccc;
  padding-bottom: 20px !important;
  margin-bottom: 10px;
}
#search-input {
  display: inline-block;
  width: auto;
  padding: 10px;
  background: rgba(0, 0, 0, 0.03);
  border: thin solid rgb(238, 238, 238);
}
.toolbar button {
  margin: 2px;
  font-size: 15px;
  border-radius: 15px;
}
.video-title {
  margin-bottom: 20px;
}
span {
  font-size: 20px;
  line-height: 30px;
}
.transcription-container span:focus {
  background: red !important;
}
span.focus {
  border: none;
  border-left: 1px solid #000;
  animation-name: cursor;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
}
.video-title-text {
  width: 100%;
  font-size: 2em;
  border: none;
  outline: none;
  background: transparent;
}

@keyframes cursor {
  0% {
    border-left: 1px solid #000;
  }
  50% {
    border-left: 1px solid #fff;
  }
  100% {
    border-left: 1px solid #000;
  }
}
.time-stamp span,
.time-stamp strong {
  pointer-events: none;
}
