.login-wrapper {
  text-align: center;
}
.he-logo {
  max-width: 120px;
  margin-bottom: 40px;
}
.login-btn {
  float: right;
  border: thin solid #000;
  background: #000;
  color: #fff;
  cursor: pointer;
  padding: 3px 20px;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  outline: none !important;
}
