.library {
  height: 80%;
}
.library form {
  margin-bottom: 50px;
}
.search-link {
  color: #17a2b8;
  display: inline;
}
.library-list {
  overflow-y: auto;
  /* max-height: 90%; */
  /* box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2); */
}
.library .row {
  /* height: 90%; */
}
.library ul {
  padding: 0;
}
.library ul .col-md-1 {
  padding-left: 0;
}
li.record {
  list-style: none;
  background: rgb(252, 252, 252);
  border: thin solid rgb(245, 245, 245);
  margin: 20px 0;
  padding: 15px;
  border-left: 3px solid #17a2b8;
  position: relative;
}
.delete-button {
  position: absolute;
  top: -15px;
  right: 10px;
  color: #000;
  z-index: 2;
}
.delete-button:hover {
  cursor: pointer;
  text-decoration: none;
  color: red;
}

li.record-pending {
  list-style: none;
  background: rgb(252, 252, 252);
  border: thin solid rgb(245, 245, 245);
  margin: 20px 0;
  padding: 15px;
  border-left: 3px solid #17a2b8;
  animation-name: pending;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  position: relative;
}

li.record-pending .delete-button {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #000;
  z-index: 2;
}
li.record-pending .delete-button:hover {
  cursor: pointer;
  text-decoration: none;
  color: red;
}
@keyframes pending {
  0% {
    border-left: 3px solid #17a2b8;
  }
  50% {
    border-left: 3px solid rgb(0, 180, 75);
  }
  100% {
    border-left: 3px solid #17a2b8;
  }
}

.video-icon {
  background: #222;
  width: 50px;
  height: 50px;
}
.form-link {
  display: block;
}
h5 {
  font-size: 16px;
}
li.record h5 {
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 20px;
  /* letter-spacing: 1px; */
}
li.record p,
li.record-pending p {
  font-size: 16px;
  line-height: 28x;
  color: #777;
  margin: 0;
}
li.record a {
  /* font-size: 12px;
  line-height: 20px;
  color: rgb(0, 183, 255); */
  margin-top: 20px;
}
.records-number {
  margin-top: 10px;
}
.row-date {
  color: #000;
}
.library-list a {
  font-size: 16px;
}
