html {
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  height: 100%;
  /* padding-top: 100px; */
}

.round-btn-inverted {
  float: right;
  border: thin solid #fff;
  background: #fff;
  color: #000;
  cursor: pointer;
  padding: 3px 20px;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  outline: none !important;
}
.error-msg {
  color: rgb(190, 2, 2);
  display: block;
  margin-bottom: 20px;
}
.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(256, 256, 256, 0.97);
  z-index: 100;
  text-align: center;
  font-size: 0.8em !important;
}

.loading .spinner-border {
  margin-left: 20px;
}

.spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-row {
  display: block;
}
@media print {
  .no-print {
    display: none;
  }

  .transcription-container {
    height: auto !important;
  }

  .container-fluid .col-md-7 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    height: auto !important;
  }
}
