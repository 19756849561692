.fileUploadCont {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  margin: 40px auto;
  padding: 30px;
  width: 550px;
}

.fileUploadCont input[type="text"] {
  margin: 10px 0;
  border: none;
  border-radius: 0;
  border-bottom: thin solid #ccc;
}
.fileUploadCont input[type="text"]:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none !important;
}

.header {
  color: #3f4c6b;
  font-size: 18px;
  font-weight: 450;
}

.upload-form {
  background-color: #c3d9ff8f;
  border-radius: 4px;
  display: block;
  margin: 40px auto;
  padding: 20px;
  text-align: left;
}

.upload-form button {
  display: block !important;
  margin: 20px auto;
}
.upload-form input[type="text"] {
  margin: 10px 2px;
}
